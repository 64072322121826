<template>
  <v-container class="reset-password" fill-height fluid>
    <v-row align="center" justify="center">
      <v-col></v-col>
      <v-col cols="12" sm="3">
        <v-img :src="url+'icons/logo-d+.png'" :height="32" :max-width="200" contain />
        <v-card class="reset-password-panel elevation-0" :width="width" max-width="100%">
          <v-card-text v-if="!completed">
            <v-text-field label="New Password" v-model="password" class="login-fields" :messages="passwordFailReason" light solo></v-text-field>
          </v-card-text>
          <v-card-text v-else>
            Your password has been updated
          </v-card-text>
          <v-card-actions class="pa-0">
            <v-spacer/>
            <v-btn v-if="!completed" @click="updatePassword()" text>
              Reset Password
            </v-btn>
            <v-btn v-else @click="navigate('./login')" tile color="primary">
              Login
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col></v-col>
    </v-row>
    <v-spacer/>
  </v-container>
</template>

<script>
import { Api } from '../js/api';
import { EventBus } from '../eventBus';

export default {
  name: 'ResetPassword',
  components: {
  },
  data: () => ({
    completed: false,
    key: null,
    userId: '',
    password: '',
    passwordFailReason: ''
  }),
  computed: {
    url () {
      return process.env.VUE_APP_CLIENT_PROTOCOL + process.env.VUE_APP_CLIENT_URL;
    }
  },
  methods: {
    navigate: function (path) {
      this.$router.push(path).catch(()=>{});
    },
    updatePassword: function () {
      let self = this
      self.validatePassword(self.password).then(function (res) {
        if (res) {
          if (self.key) {
            let data = {
              token: self.key,
              id: self.userId,
              password: self.password
            }

            // check if the key is valid
            Api.post('Auth/tryPasswordReset', data).then(function (res) {
              if (res.valid) {
                self.completed = true;
              } else {
                EventBus.$emit('alert', {
                  text: 'The reset password link has expired',
                  alertColor: '#a70505'
                })
              }
            })
          }
        }
      })
    },
    validatePassword: function (pass) {
      return new Promise((resolve) => {
        // length
        if (pass.length < 9) {
          this.passwordFailReason = 'Must be longer than 8 characters'
          resolve(false);
          return;
        }

        // // lowercase
        // if (!pass.match(/[a-z]/g)) {
        //   this.passwordFailReason = 'Must contain a lowercase letter';
        //   resolve(false);
        //   return;
        // }
        // // uppercase
        // if (!pass.match(/[A-Z]/g)) {
        //   this.passwordFailReason = 'Must contain an uppercase letter';
        //   resolve(false);
        //   return;
        // }
        // // numbers
        // if (!pass.match(/[0-9]/g)) {
        //   this.passwordFailReason = 'Must contain a number';
        //   resolve(false);
        //   return;
        // }

        // // symbols
        // if (!pass.match(/[^a-zA-Z\d\s:]/g)) {
        //   this.passwordFailReason = 'Must contain a symbol';
        //   resolve(false);
        //   return;
        // }

        this.passwordFailReason = null;
        resolve(true);
      })
    },
  },
  mounted () {
    let self = this;

    // get the reset key
    self.key = self.$route.query.k;
    self.userId = self.$route.query.i;
  }
}
</script>
<style lang="scss">
.reset-password {
  height: 100vh;
}
.reset-password-panel {
  background-color: inherit!important;
  border-radius: 0px !important;
}
</style>
